@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;600;700&family=Lora:wght@400;600;700&display=swap');

@font-face {
    font-family: 'Sharp Grotesk';
    src: url('../fonts/SharpGroteskBold25.otf') format('opentype'),
        url('../fonts/SharpGroteskBold25.ttf') format('truetype');
}

/* ================================================ */
/* Init */
/* ================================================ */
body {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    color: #1B262C;
}

a {
    -moz-transition: all .8s ease;
    -webkit-transition: all .8s ease;
    -o-transition: all .8s ease;
    transition: all .8s ease;
    color: #041e42;
}

.main span {
    line-height: normal;
}

a>i {
    font-style: normal;
}

.wrapper {
    border-top: none;
}

.container:not(.grid12-12):not(.xm-grid-product):not(.footer) {
    max-width: 100%;
}

.container.grid12-12 {
    margin-left: auto;
    margin-right: auto;
    display: block !important;
    float: none;
    clear: both;
}

button.button {
    -webkit-border-fit: lines;
    overflow: visible;
    width: auto;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
}

button.button span {
    text-transform: uppercase;
    border-radius: 30px;
    background-color: transparent;
    color: #041e42;
    float: left;
    display: block;
    padding: 0;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -webkit-transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -o-transition: color .3s ease-in-out, background-color .3s ease-in-out;
}

.product-view .btn-cart:hover span,
button.btn-checkout:hover span,
button.button:hover span {
    background-color: #041e42;
    color: #fff;
}

button.button span span {
    font-family: 'Barlow', sans-serif;
    border: 2px solid #041e42;
}

.buttons-set button.button {
    margin-left: 0;
    margin-right: 5px;
    margin-bottom: 5px;
}

.button.btn-inline span,
.button.btn-checkout span {
    background-color: transparent;
    color: #041e42;
    border: 1px solid #041e42;
}

.button.btn-inline:hover span,
.button.btn-checkout:hover span {
    background-color: #041e42;
}

button.add-cart-button {
    margin-right: 15px !important;
}

label {
    color: #041e42
}

.no-gutter.grid12-12 .page-content img,
.no-gutter.grid12-12 div[class*="carousel"] img {
    width: 100%;
}

.banner-caption,
.banner-caption-left,
.banner-caption-right,
.banner-caption-center {
    color: #fff;
    position: absolute;
    font-size: 2vw;
    top: 50%;
    transform: translateY(-50%);
}

.banner-caption-right {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.banner-caption-center {
    right: 30px;
}

.banner-caption-left {
    left: 30px;
}

#root-wrapper span.fa {
    font-size: 1.75em !important;
}

iframe {
    width: 100%;
}

.grey-back-text {
    padding: 1%;
    background-color: #f6f7f9;
}

.grey-back-text span,
.grey-back-text p {
    line-height: normal;
}

.grey-back-text a.button span span {
    display: none;
}

.grey-back-text,
.feature-image {
    margin: 0;
}

.modal-open .modal {
    text-indent: 0;
}

@media only screen and (min-width: 960px) {
    .show-below-960 {
        display: none !important;
    }
}

@media only screen and (max-width: 959px) {
    .hide-below-960 {
        display: none !important;
    }

    .main.container>.col-main.grid-full {
        width: 100%;
        margin: 0;
    }

    .grid12-12.no-gutter .nested-container {
        width: 100%;
        margin: 0;
    }
}

@media only screen and (max-width: 767px) {
    html:not(.page-type-home) .main.container {
        width: 94%;
    }
}

/* ================================================ */
/* Font and Colours */
/* ================================================ */
.main-font,
h1,
h2,
h3,
h4,
h5,
h6,
.section-title,
.products-list .product-name,
.products-grid .product-name,
h3.product-name,
.feature .heading,
.accordion .heading,
.block .block-title,
#opc-login h3,
.box-account .box-head h2,
.order-items h2.table-caption,
.order-items h2.sub-title,
.order-items .order-comments h2,
.product-view .box-reviews dt .heading,
.gen-tabs .tabs a,
.footer .heading,
#subscribe-form label,
.caption,
.heading {
    font-family: 'Lora', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.nav-regular li>a,
.nav-regular .nav-submenu--mega li>a {
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
}

#root-wrapper a:hover span.fa:before,
.header-top a:hover,
p.required,
.product-options dt label.required em,
.form-list label.required em,
a:hover,
.vertnav li.current>a,
.header .dropdown-menu a:hover,
.header .open>.dropdown-toggle.cover>div a:hover,
.header .form-search .search-autocomplete li:hover {
    color: #041e42;
}

.module-multi-location a:hover {
    color: #ffbf3f;
}

#nav .nav-panel--dropdown a:hover {
    color: #fff;
}

.header-top-container,
.module-multi-location.multi-location-wrapper {
    text-transform: uppercase;
}

.module-multi-location.multi-location-wrapper {
    text-align: center;
}

.main-container {
    background-color: #FFF;
    padding: 0 !important;
}

html:not(.page-type-home) .main-container {
    padding-top: 30px !important;
}

.price-box .minimal-price .price,
.price-box .regular-price .price,
.price-box-bundle .full-product-price .price {
    color: #041e42;
}

.page-content>p a.button span {
    font-size: 26px;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border-radius: 30px;
}

.page-content>p a.button:hover span {
    background-color: #041e42;
}

#product-disclaimer {
    color: #7d7f80;
    padding: 15px;
    font-size: 14px;
}

#product-disclaimer .bold-disclaimer {
    font-weight: bold;
}

.add-to-links a.button span,
.add-to-links a span {
    color: #041e42;
    font-size: 14px;
}

/* ================================================ */
/* Header */
/* ================================================ */

header#header.floating {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: none;
}

header#header.sticky {
    background-color: #fff;
}

#header .no-gutter .nested-container {
    width: 100%;
    margin: 0;
}

.xm-grid-header>.container {
    width: 100%;
    margin: 0;
    padding: 0;
}

.header .userCode {
    margin: 0 !important;
}

.header .userName {
    max-width: 250px;
}

.header .userCode,
.header .userName,
.header .userSwitch,
.header .welcome-msg,
.header .feature-icon-hover,
.module-multi-location.multi-location-wrapper {
    font-weight: 700;
    font-size: 14px;
    color: #041e42 !important;
    transition: none;
}

header#header.sticky .header .userName,
header#header.sticky .header .userSwitch,
header#header.sticky .header .userSwitch a,
header#header.sticky .header .welcome-msg,
header#header.sticky .header .dropdown.lang-switcher.item:not(.open) .value {
    color: #041e42 !important;
}

.header .dropdown.lang-switcher.item:not(.open) .value {
    color: #fff;

}

.header .dropdown.lang-switcher.item:not(.open) .caret {
    border-top-color: #fff;
}

header#header.sticky .header .dropdown.lang-switcher.item:not(.open) .caret {
    border-top-color: #041e42;
}

.header .userSwitch {
    margin-left: 10px !important;
}

.header .userSwitch a,
.header .userSwitch a:hover {
    border-bottom: 1px solid #fff;
}

.header-container {
    position: relative;
}

.header-primary {
    padding: 0;
    position: relative;
}

.header-top,
.header .dropdown,
.module-multi-location.multi-location-wrapper,
.module-multi-location.multi-location-wrapper>*,
.link-logout,
.link-admin,
.link-account,
#header .link-wishlist,
#header .link-login,
#header .link-register {
    line-height: 40px;
}

.module-multi-location.multi-location-wrapper>* {
    display: inline-block;
}

.header-primary-container {
    border-bottom: #e0e0e0 solid 1px;
}

header#header .top-links>.links>li>a,
header#header .links-container>.links>.company>dl.company-links dt a {
    color: #aaa !important;
    border: none;
    background-image: none;
}

header#header .links>li>a:hover {
    color: #ffbf3f !important;
}

.item-logo.grid12-0.show-below-960.grid12-4,
.item-logo.grid12-0.show-below-960.grid12-8 {
    float: none;
}

.user-menu {
    margin: 0;
}

#mini-cart {
    font-weight: bold;
}

div#mini-cart .hide-below-960 {
    text-indent: -9999px;
    position: relative;
}

div#mini-cart .hide-below-960 .cart-total {
    text-indent: 0;
    position: absolute;
    top: -2px;
    right: 4px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background-color: #ffbf3f;
    border-radius: 50%;
    color: #041e42 !important;
}

#mini-cart.dropdown:not(.open) .feature-icon-hover a,
.module-search .form-search .button-search .fa {
    color: #041e42 !important;
    transition: none;
}

header#header.sticky #mini-cart.dropdown:not(.open) .feature-icon-hover a,
header#header.sticky .module-search .form-search .button-search .fa {
    color: #041e42 !important;
}

#mini-cart.dropdown.open .feature-icon-hover a,
.module-search .form-search .button-search:hover .fa {
    color: #333;
}

#mini-cart .feature-icon-hover a span.fa {
    padding-top: 8px;
    padding-bottom: 8px;
}

#mini-cart.dropdown:not(.open) .feature-icon-hover .caret {
    border-top-color: #fff;
}

.desktop-cart #mini-cart.dropdown:not(.open) .hide-below-960 {
    top: 10px;
}

.desktop-cart .hide-below-960 {
    text-indent: -9999px;
    position: absolute;
    right: 0;
    line-height: normal;
}

.desktop-cart .hide-below-960 .cart-total {
    text-indent: 0;
    float: left;
}

.desktop-cart .empty,
.desktop-cart .caret {
    display: none;
}

.header .item-switch-language .switch-language-wrapper>.item-right {
    margin-top: 5px;
    margin-bottom: 5px;
}

.header .item-switch-language .switch-language-wrapper .dropdown-icon {
    clear: left;
}

.header .item-switch-language .switch-language-wrapper .dropdown-icon,
.header .item-switch-language .switch-language-wrapper .dropdown-icon+a {
    float: left;
}

.links>li>a {
    margin-right: 5px;
    color: #041e42;
    transition: none;
    padding: 0;
}

/*
.link-logout, .link-admin, .link-account, #header .link-wishlist, #header .link-login, #header .link-register {
    text-indent:-9999px;
}
.link-logout::after, .link-admin::after, .link-account::after, #header .link-wishlist::after, .link-register::after, .link-login::after {
    font-family:fontAwesome;
    font-size:18px;
    text-indent:0;
    float:left;
    padding-left: 8px;
}
.link-logout::after {
    content:"\f08b";
}
.link-admin::after {
    content:"\f013";
}
.link-account::after {
    content:"\f007";
}
.link-login::after {
    content:"\f090";
}
.link-register::after {
    content:"\f234";
}
*/
.link-logout,
.link-admin,
.link-account,
#header .link-wishlist,
#header .link-login,
#header .link-register {
    font-size: 14px;
    font-weight: bold;
}

.dropdown-toggle * {
    display: inline-block;
}

.search-column {
    margin: 0;
    width: 1.33%;
}

.header-multi-column {
    margin: 0;
    width: 11.33%;
}

.logo-wrapper .nav-cart {
    display: none;
}

.mobile-header-container.grid-full,
.mobile-header-container.grid12-12 {
    overflow: hidden;
}

html[lang="en"] .lang-switcher .dropdown-icon.flag,
.lang-switcher .dropdown-menu .dropdown-icon[style*="us.png"] {
    background-image: url('../images/ca-flag.png') !important;
}

html[lang="fr"] .lang-switcher .dropdown-icon.flag,
.lang-switcher .dropdown-menu .dropdown-icon[style*="fr.png"] {
    background-image: url('../images/qc-flag.png') !important;
}

.mobile-header-container .item-switch-customer.item {
    display: block;
    float: none;
    text-align: center;
}

.mobile-header-container .item-switch-customer.item:not(.admin) {
    display: none;
}

.userSwitch a {
    text-indent: -9999px;
    display: block;
    margin-left: 10px;
}

.userSwitch a::after {
    text-indent: 0;
    float: left;
    content: "\f0ec\00a0\f2bc";
    font-family: fontAwesome;
}

.header .userSwitch a,
.header .userSwitch a:hover {
    border: none;
}

.top-header-container a {
    color: #041e42;
}

@media only screen and (min-width: 1280px) {
    #mini-cart .feature-icon-hover a span.fa {
        margin-right: 5px;
    }
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {

    .header .userCode,
    .header .userName,
    .header .userSwitch,
    .header .welcome-msg,
    .module-multi-location.multi-location-wrapper,
    .link-logout,
    .link-admin,
    .link-account,
    #header .link-wishlist,
    #header .link-login,
    #header .link-register {
        font-size: 12px;
        margin: 5px 0 !important;
    }

    .header .feature-icon-hover {
        font-size: 12px;
    }

    div#mini-cart .hide-below-960 .cart-total {
        height: 20px;
        width: 20px;
        line-height: 20px;
    }
}

@media only screen and (min-width: 960px) {
    #inline-search-results {
        left: -600px;
        min-width: 50vw;
    }

    #inline-search-results li {
        padding: 0;
    }
}

@media only screen and (max-width: 959px) {

    #root-wrapper #header .std span.fa,
    #root-wrapper #header .std i.fa {
        font-size: 28px !important;
        display: block;
    }

    #root-wrapper #header .std i.fa.fa-search {
        font-size: 24px !important;
    }

    .module-multi-location.multi-location-wrapper {
        text-indent: -9999px;
    }

    .module-multi-location.multi-location-wrapper a::after {
        text-indent: 0;
        content: "\f041";
        font-family: fontAwesome;
        font-size: 28px;
        line-height: normal;
        display: block;
        position: absolute;
        right: 180px;
        top: 17px;
    }

    header#header.sticky .userCode {
        padding: 0;
    }

    header#header.sticky .form-search .button,
    header#header .fly-out .form-search .button,
    header#header .form-search .button {
        color: #041e42;
    }

    .header .userName,
    .header .userSwitch,
    .header .userSwitch a,
    .header .welcome-msg,
    .header .userCode {

        margin: 0 !important;
    }

    .mobile-header-container .header .item-switch-customer small.userSwitch {
        margin-left: 10px !important;
    }

    header#header .userCode,
    #mini-cart .feature-icon-hover a span.fa,
    .form-search .button {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        line-height: 60px;
    }

    header#header .item-switch-customer .userCode {
        line-height: normal;
    }

    .xm-grid-header .mobile-header-column {
        width: auto !important;
    }

    .xm-grid-header .grid12-6 {
        width: 48% !important;
    }
}

/* ================================================ */
/* Menu */
/* ================================================ */
.nav-item--home {
    display: none;
}

.nav.has-outline>.grid-full {
    margin: 7px 0 0 0 !important;
}

#nav {
    z-index: 1000;
}

.nav-item.level0 {
    font-size: 16px;
}

.mm-panels .sidebar.sidebar-filter.hide-below-960 {
    display: block !important;
}

#nav,
.mobnav-trigger {
    border-bottom: none;
}

.mobnav-trigger,
.mobnav-trigger.active,
.mobnav-trigger:hover {
    background-color: transparent;
}

.nav-regular {
    background-color: transparent;
}

#nav.nav-mobile ul.level0 .nav-item.current>a,
#nav.nav-mobile ul.level0 .nav-item>a:hover,
.cms-index-index .nav-regular .nav-item--home>a,
.nav-mobile .nav-item.level0.current>a,
.nav-mobile .nav-item.level0>a:hover {
    background-color: transparent;
}

.nav-container a,
.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular a,
.mobnav-trigger a {
    color: #041e42;
    transition: none;
}

.nav-container li.nav-item.level0:hover a,
.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular li.nav-item.level0:hover a,
.nav-container .opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular .nav-panel--dropdown a {
    color: #ffbf3f !important;
}

header#header.sticky .nav-container a,
header#header.sticky .opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular a,
header#header.sticky .mobnav-trigger a {
    color: #333;
}

header#header.sticky .nav-container .nav-item a,
header#header.sticky .opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular .nav-item.level0:hover>a,
header#header.sticky .opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular .nav-panel--dropdown a {
    color: #fff;
}

.nav-panel--dropdown .nav-item.active a.module-sub-categories-title span {
    color: #fff;
    font-size: 17px;
}

.nav-item.active>a,
.module-sub-categories-title.active {
    color: #ffbf3f !important;
}

.nav-item.active>a .caret {
    border-top-color: #ffbf3f !important;
}

.module-sub-categories div.nav-item>.module-sub-categories-title {
    font-weight: 600;
}

.main-font,
h1,
h2,
h3,
h4,
h5,
h6,
.section-title,
.products-list .product-name,
.products-grid .product-name,
h3.product-name,
.feature .heading,
.accordion .heading,
.mobnav-trigger,
.nav-mobile li.level0>a,
.nav-mobile li.level1>a,
.nav-mobile li.level2>a,
.block .block-title,
#opc-login h3,
.box-account .box-head h2,
.order-items h2.table-caption,
.order-items h2.sub-title,
.order-items .order-comments h2,
.product-view .box-reviews dt .heading,
.gen-tabs .tabs a,
.footer .heading,
#subscribe-form label,
.caption,
.heading {
    font-family: 'Lora', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.nav-regular li.level0>a,
.nav-regular .nav-submenu--mega>li>a {
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
}

h3 {
    font-size: 16px;
}

.products-grid .product-name,
.products-list .product-name,
h3.product-name {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
}

.block .block-title {
    border-bottom: none;
    display: none;
}

.sorter .amount {
    color: #aaa;
}

.module-category-misc div:first-child {
    margin-left: 0px;
}

.desktop-nav-column {
    width: auto;
}

.nav-regular {
    display: flex;
    justify-content: right;
}

.nav-regular li.level0>a {
    font-size: 14px;
    letter-spacing: 3px;
}

.nav-regular li.level0>a>span {
    display: inline-block;
    white-space: nowrap;
}

#nav .nav-item.level0.parent>a .caret {
    border-top-color: #fff;
    display: none;
}

.nav-regular .mega>.nav-panel--dropdown,
.nav-regular li.level0>.nav-panel--dropdown {
    border-top: none;
    width: 100vw !important;
    left: 0 !important;
    top: 137px !important;
}

.nav-regular li.level0>.nav-submenu.nav-panel--dropdown {
    width: 16em !important;
}

.nav-mobile.opt-sb2 li.level0>div>div>.nav-block {
    padding: 0 10px;
}

.nav-mobile .opener:before {
    content: "\f107" !important;
    padding: 10px 10px 10px 20px !important;
}

.nav-mobile .opener.active:before,
.nav-mobile li.active>.opener:before {
    content: "\f106" !important;
}

.nav-search.fly-out {
    display: none;
}

.nav-search .form-search .button-close {
    display: none;
}

.form-search .button i {
    font-style: normal;
}

.breadcrumbs .nav-filter {
    float: right;
    font-size: 16px;
    font-weight: 400;
}

#breadcrumbs a {
    text-transform: uppercase;
}

.details-main-container .breadcrumbs {
    margin-bottom: 10px;
    margin-left: 10px;
    display: none;
}

#filter_list_box dt,
#mm-filter_list_box dt {
    font-weight: 600;
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 15px;
}

#filter_list_box dd .fa {
    margin-right: 6px;
}

#filter_list_box dd a {
    margin-bottom: 10px;
}

.std i {
    font-style: normal;
    font-size: 18px;
    margin-bottom: 5px;
    margin-right: 6px;
}

.category-products .toolbar {
    border: none;
    width: 100%;
    text-align: right;
}

.category-products .toolbar .sorter {
    display: inline-block;
}

.category-products-grid.hover-effect .item:hover,
.products-list.hover-effect .item:hover {
    margin-left: 0;
    margin-right: 0;
    box-shadow: none;
    z-index: 0;
}

.category-products-grid.hover-effect .item:hover {
    padding-left: 1%;
    padding-right: 1%;
}

.products-list.hover-effect .item:hover {
    padding-left: 0;
    padding-right: 0;
}

.sidebar a {
    font-size: 16px;
}

.mini-products-list li {
    list-style: none;
}

.breadcrumbs li {
    display: inline-block;
    vertical-align: top;
}

.product-shop h1 {
    font-size: 37px;
    text-transform: initial;
}

.module-search.flyout .form-search .input-text:focus,
.module-search.flyout .form-search .input-text:hover {
    width: 200px !important;
}

.mm-menu {
    font-size: 16px;
}

.mm-navbar__title {
    background-color: #ffbf3f;
}

.mm-navbar__title span {
    color: #041e42 !important;
}

.mm-menu .mm-listitem a {
    color: #fff !important;
}

.mm-panels>.mm-panel,
.mm-listitem,
.mm-listview {
    background-color: #041e42;
    color: #ffbf3f;
}

.site-notifacition {
    display: none !important;
}

.mm-menu a {
    font-weight: bold;
}

.mm-btn:after {
    border-color: #fff !important;
}

@media only screen and (max-width: 959px) {

    .form-list .field,
    .form-list input.input-text,
    .form-list select,
    .progress,
    div[class^=strength_meter_passwd] {
        width: 100%;
    }

    .nav-item.active>a,
    .module-sub-categories-title.active {
        color: #000000 !important;
        background-color: #f3f3f3 !important;
    }

    .nav-item.active>.opener {
        color: #fff !important;
    }

    .xm-grid-product .grid-left,
    .xm-grid-product .grid-right {
        width: 100% !important;
    }

    .product-shop h1,
    .product-shop .product-type-data {
        text-align: center !important;
    }

    .grid-col2-sidebar,
    .product-search-container .sidebar-category,
    .product-search-container .has-sidebar.grid-col2-main {
        width: 100%;
    }

    .details-main-container .breadcrumbs {
        display: none;
    }

    .sorter .sort-by {
        display: none;
    }

    .links>li>a {
        padding: 0 10px;
    }

    .item-welcome-msg {
        display: block !important;
    }

    .product-shop {
        margin-top: 20px;
    }

    .sidebar-category .block-content {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .sidebar-category .accordion {
        overflow-x: scroll;
        width: max-content;
    }

    .sidebar-category .accordion li {
        display: inline-block;
        width: auto !important;
    }

    .grid-full,
    .grid12-12 {
        overflow: auto;
    }

    .header-primary-container {
        display: none;
    }

    .search-wrapper-mobile {
        width: 36px !important;
        height: 36px;
        margin: 0 !important;
        padding: 0;
        float: none;
        vertical-align: top;
    }

    .search-wrapper-mobile .form-search input {
        display: none;
    }

    .mobnav-trigger-wrapper {
        display: inline-block !important;
        vertical-align: top;
        float: none !important;
        padding: 10px .5em !important;
    }

    .mobnav-trigger {
        padding: 0;
    }

    .mobnav-trigger>span:last-child {
        display: none;
    }

    .mobnav-trigger .trigger-icon {
        padding-top: 0;
        margin: 0;
    }

    .mobnav-trigger .trigger-icon .line {
        background-color: #5a5a5a;
    }

    .search-wrapper-centered {
        float: none !important;
        display: inline-block !important;
    }

    #mini-cart .feature-icon-hover .hide-below-960,
    #mini-cart .feature-icon-hover .empty,
    nav .feature-icon-hover .hide-below-960,
    nav .feature-icon-hover .empty {
        display: none;
    }

    #mini-cart .dropdown-toggle .caret {
        display: none;
    }

    nav .feature-icon-hover {
        padding: 0 !important;
    }

    nav .feature-icon-hover .caret {
        display: none;
    }

    nav.nav {
        text-align: right;
        height: 45px;
    }

    nav.nav .header-top {
        margin: 0;
        padding: 0;
        width: 100%;
        display: block !important;
    }

    #nav.nav-mobile.show {
        display: none !important;
    }

    .nav-mobile {
        text-align: left;
    }

    .nav-logo,
    .nav-cart,
    .nav-search {
        display: inline-block;
        vertical-align: top;
    }

    .nav-logo {
        float: left;
        width: 200px;
        margin-top: 5px;
        margin-left: 8px;
    }

    .nav-cart {
        height: 36px;
    }

    .nav-cart .fa-shopping-cart {
        font-size: 1.5em !important;
        font-style: normal;
    }

    .nav-cart .hide-below-960 {
        visibility: hidden;
        position: relative;
        width: 0;
        height: 0;
        display: block !important;
    }

    .nav-cart .hide-below-960 .cart-total {
        visibility: visible;
        position: absolute;
        left: 25px;
        top: -35px;
        border: none;
        background-color: #041e42;
        border-radius: 50%;
        color: #fff;
        width: 17px;
        height: 17px;
        text-align: center;
        font-size: 9px;
        line-height: 17px;
    }

    .nav-cart .product-details a {
        color: #888;
    }

    .nav-cart .btn-remove,
    .nav-cart .btn-edit {
        float: right;
    }

    .nav-search .input-text {
        display: none;
    }

    .nav-search .button {
        height: 36px;
    }

    .cart-mobile {
        display: inline-block !important;
        vertical-align: top;
    }

    .cart-action-buttons {
        text-align: right;
    }

    .nav-item .header-top {
        display: block !important;
    }

    .header-top .item {
        display: block;
    }

    .nav-mobile .nav-block {
        display: initial;
    }

    .nav-mobile .nav-block,
    .nav-mobile .nav-block>.grid12-12 {
        padding: 0 !important;
        margin: 0 !important;
        width: 100%;
    }

    .nav-mobile .nav-block>.grid12-12 {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .module-sub-categories .nav-item {
        width: 100% !important;
    }

    .module-sub-categories .nav-item>a {
        padding: 15px 54px 15px 20px !important;
        text-transform: uppercase !important;
        font-size: 14px !important;
        font-weight: 400 !important;
    }

    .module-sub-categories .nav-item .page-item {
        padding: 0 !important;
    }

    .module-sub-categories .nav-item .page-item>a {
        padding: 15px 10px 15px 40px !important;
        display: block;
        text-transform: uppercase;
    }

    .acco .module-sub-categories div.nav-item ul li {
        padding-left: 0 !important;
    }

    .nav-mobile .nav-panel-inner .opener {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .nav-mobile li.nav-item {
        font-size: 14px;
        background-color: #f7f7f7;
    }

    .nav-mobile .nav-item.parent a {
        background-color: transparent;
        border-bottom: 1px solid #e5e5e5;
        font-size: 14px;
        font-weight: 400;
    }

    .mm-menu {
        position: fixed !important;
    }

    .mm-navbar_sticky {
        display: none;
    }

    .mm-menu_offcanvas {
        z-index: 0 !important;
        width: 80% !important;
    }

    .mm-menu_opened {
        display: block !important;
    }

    .header-container .col-main {
        margin-bottom: 0;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .nav-item.show-below-960 {
        padding: 10px !important;
    }

    .nav-item.show-below-960>div {
        display: block !important;
        padding: 0.3em 1em;
    }

    .nav-search.fly-out {
        position: fixed;
        display: block !important;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 9999;
        width: 100% !important;
        background-color: #fff;
    }

    .nav-search.fly-out .form-search {
        position: relative;
        padding: 10px;
    }

    .nav-search.fly-out .form-search .input-text {
        width: 100%;
        max-width: 100%;
        display: block;
        border: none;
        padding-right: 70px;
    }

    .nav-search.fly-out .form-search .button {
        color: #aaa;
        top: 10px;
        right: 10px;
    }

    .nav-search.fly-out .form-search .button-search {
        right: 45px;
    }

    .nav-search.fly-out #inline-search-results {
        top: 50px;
    }

    .nav-search.fly-out .form-search .button-close {
        display: block;
    }

    .block.mm-panel.mm-panel_opened {
        text-align: left;
        padding-left: 25px;
        overflow-y: scroll;
    }
}

@media (max-width: 600px) {
    #my-menu {
        display: none !important;
    }
}

@media (min-width: 601px) {
    #mm-my-menu {
        display: none !important;
    }
}

/* ================================================ */
/* Content: Logo and Search  */
/* ================================================ */
.header .logo-wrapper {
    text-align: center;
    margin: 10px 0;
}

.header .logo-wrapper a.logo {
    display: block;
}

.header .logo {
    max-width: 120px;
    display: block;
    margin: auto;
}

.search-wrapper-centered {
    float: right;
    margin-top: 18px;
    margin-bottom: 7px;
    display: none;
    width: 25%;
}

.search-wrapper-centered .form-search {
    margin: 0 auto;
}

.search-wrapper-centered .form-search label {
    display: none;
}


.form-search .button {
    position: absolute;
    right: 110px;
    top: 15px;
    font-size: 1.375em;
    color: #353535;
    padding: .5em;
}

.search-box .search-btn-quickorder .search_button {
    border: none;
}

.nav-search.fly-out .button .fa {
    color: #041e42;
}

@media only screen and (min-width: 960px) {
    .user-link-wrapper {
        float: right;
    }

    .search-wrapper,
    .logo-wrapper {
        margin-left: 12px;
    }

    .search-wrapper {
        margin-right: 20px;
        margin-top: 15px;
    }

    .search-wrapper .form-search .input-text {
        width: 36px;
        padding-right: 36px !important;
        background-color: transparent;
        z-index: 1;
        position: relative;
        color: rgb(56, 56, 56) !important;
        float: right;
    }

    .search-wrapper .form-search .input-text:hover,
    .search-wrapper .form-search .input-text:focus {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.8) !important;
        color: #fff;
        transition: width 300ms ease-in-out;
        -moz-transition: width 300ms ease-in-out;
        -webkit-transition: width 300ms ease-in-out;
        -o-transition: width 300ms ease-in-out;
    }

    #header.sticky .search-wrapper .form-search .input-text:hover,
    #header.sticky .search-wrapper .form-search .input-text:focus {
        background-color: rgba(255, 255, 255, 0.2) !important;
    }
}

@media only screen and (min-width: 768px) {
    .logo-wrapper>a {
        margin: 0 auto !important;
    }
}

/* ================================================ */
/* Content: Product Listing  */
/* ================================================ */
#mm-nav-filter {
    display: block;
    width: 80vw;
    text-align: left;
    margin-left: 5vw;
}

/* ================================================ */
/* Content: Product Detail  */
/* ================================================ */
.product-image-container .more-image-carousel h3.product-name {
    font-size: 8px;
    line-height: normal;
    margin-top: 5px;
}

.product-image-container .more-image-carousel span.item-code,
.product-image-container .more-image-carousel .price-box {
    display: none;
}

.product-image-container .more-image-carousel {
    font-size: 8px;
    line-height: normal;
    margin-top: 5px;
}

.product-shop .price-box .regular-price .price {
    font-size: 2em;
}

.matrix-table .price-box .regular-price .price {
    font-size: 1em;
}

.product-shop .btn-cart span span {
    padding: 5px 40px;
    font-size: 1.1666em;
}

.product-shop .z4 {
    background-color: #041e42 !important;
}

.img-box-style1 .product-image {
    border: none;
    padding: 0;
}

.product-shop h1 {
    color: #041e42;
}

.product-shop ul {
    list-style: none;
}

.product-shop .product-options dd {
    padding-left: 0;
}

.price-box .regular-price .price,
.price-box .minimal-price .price,
.price-box-bundle .full-product-price .price {
    color: #041e42;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
}

.product-options dt label,
.product-shop .qty-wrapper label {
    font-size: 1.3em;
}

.product-shop .qty-wrapper {
    margin-top: 5px;
    margin-bottom: 15px;
}

.product-shop #unit-messure-wrapper {
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 15px;
}

.product-shop .qty-wrapper input {
    font-size: 1.2em;
    width: 50% !important;
}

.product-view .product-shop #add-to-cart-block {
    padding-right: 15px;
}

.product-shop button.add-cart-button {
    width: 100%;
    margin-left: 0;
    margin-right: 0 !important;
}

.product-shop button.add-cart-button span {
    width: 100%;
    padding: 0;
    color: #041e42;
    background-color: #fff;
}

.product-shop button.add-cart-button:hover span,
.product-shop button.add-cart-button span:hover {
    background-color: #041e42 !important;
    color: #fff;
}

.product-shop .short-description {
    font-size: 19px;
    line-height: 1.5;
    font-weight: 100;
}

.product-shop ul li {
    font-size: 1em;
    line-height: 1.75;
    font-weight: 100;
}

.product-shop p {
    font-size: 16px;
    line-height: 1.75;
    font-weight: 100;
}

.product-shop .product-type-data {
    font-size: 13px;
    margin-top: 11px;
}

.product-shop .product-type-data .qohConBody {
    font-size: 16px;
}

.variations .swatches-con.list .swatch .text,
.variations .swatchesCon.list .swatch .text {
    height: 33px !important;
    line-height: 33px !important;
    font-size: 14px !important;
    overflow: hidden;
}

.variations .swatches-con.list .swatch.available:hover .text,
.variations .swatches-con.list .swatch.selected .text {
    border: 4px solid #ccc !important;
    height: 27px !important;
    line-height: 27px !important;
    font-size: 14px !important;
}

.action-box.clearer {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #e5e5e5;
}

#unit-messure-wrapper {
    color: #717171;
}

#unit-messure-wrapper label[for="unit_of_measure"] {
    color: #041e42;
}

.product-view .add-to-links a .icon,
.xm-grid-product .add-to-links a .icon {
    transition: all 0.3s ease;
    background-color: transparent;
}

#itemslider-related {
    font-size: 14px;
}

#itemslider-related .product-name a {
    color: #041e42;
}

#itemslider-related .product-name .item-code {
    color: #7d7f80;
}

.gen-tabs .tabs a {
    background-color: #fff;
    color: #041e42;
    border-top: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
}

.gen-tabs-style1 .tabs a.current {
    padding-top: 1px;
}

.gen-tabs .acctab:hover,
.gen-tabs .acctab:focus,
.gen-tabs .acctab.current,
.gen-tabs .tabs a.current {
    color: #041e42;
    background-color: #e5e5e5;
}

.gen-tabs li {
    width: 50% !important;
}

/*TABLES*/
.product-view table,
.xm-grid-product table {
    width: 100%;
    color: #717171;
}

.product-view tr,
.xm-grid-product tr {
    border-top: 1px solid #E5E5E5;
}

.product-view tr:first-child,
.xm-grid-product tr:first-child {
    border-top: none;
}

.product-view td,
.xm-grid-product td {
    padding: 10px;
    min-width: 200px;
    font-size: 14px;
}

.product-view td:first-child,
.xm-grid-product td:first-child {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
}

.accordion li a:hover,
.accordion li a:focus {
    color: #041e42;
}

@media only screen and (min-width:1280px) {
    .product-view .product-shop button.add-cart-button {
        min-width: 175px;
    }
}

@media only screen and (min-width:960px) and (max-width:1279px) {
    .product-view .product-shop button.add-cart-button {
        min-width: 140px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
    .product-image-container .more-image-carousel h3.product-name {
        font-size: 5px;
    }
}

@media only screen and (max-width:767px) {
    .product-image-container .more-image-carousel h3.product-name {
        font-size: 12px;
    }

    .product-view td,
    .xm-grid-product td {
        display: block;
    }

    .product-view td:first-child,
    .xm-grid-product td:first-child {
        padding-bottom: 0;
    }

    .product-view td:nth-child(2),
    .xm-grid-product td:nth-child(2) {
        padding-top: 0;
    }
}

/* ================================================ */
/* Content: Others  */
/* ================================================ */
.page-type-contents .breadcrumbs {
    display: none;
}

.promo-header {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    color: #fff;
    background-color: #041e42;
    padding-top: 11px;
    font-size: 1em;
    letter-spacing: 0.5px;
}

.homepage-carousel-caption {
    margin: 0;
    position: absolute;
    bottom: 7%;
    left: 7%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: left;
    font-weight: 400;
    text-transform: none;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    color: #fff;
}

.homepage-carousel-caption h2 {
    font-size: 3em;
    font-weight: 400;
    text-align: left;
    margin: 0;
    line-height: 1.5em;
    text-transform: uppercase;
}

.homepage-button {
    padding: 20px;
    font-family: 'Barlow', sans-serif;
    float: left;
    background-color: #041e42;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: none;
    border-radius: 30px;
    -webkit-transition: background-color 0.15s ease-out;
    -moz-transition: background-color 0.15s ease-out;
    -o-transition: background-color 0.15s ease-out;
    transition: background-color 0.15s ease-out;
}

.homepage-button:hover {
    background-color: #1B262C;
    color: #fff;
}

.fa-chevron-right:before {
    content: "\f054";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
}

.slider-arrows2 .owl-controls div {
    background-color: transparent;
}

.page-content h2 {
    font-size: 2em;
}

.request-pricelist-column ul.form-list li.fields.module_form_input:nth-of-type(3),
.request-pricelist-column ul.form-list li.fields.module_form_input:nth-of-type(4) {
    display: none;
}

/*Newsletter Module Home page*/
#subscribe-form form {
    display: block;
}

#subscribe-form form label {
    color: #fff;
    display: block;
    margin-bottom: 17px;
}

#subscribe-form {
    padding: 1%;
    padding-bottom: 30px;
}

.section-title.padding-right {
    padding-right: 0;
    font-size: 1.75em;
    text-transform: none;
    font-weight: bold;
    color: #041e42;
}

.newsletter-wrapper {
    width: 100%;
    margin-bottom: -20px;
}

.newsletter .section-title {
    border-bottom: none;
    background-color: #5c5c5c;
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    letter-spacing: 0.5px;
}

.newsletter-wrapper .text_title {
    clear: both;
    display: block;
    padding: 10px;
    color: #fff;
}

.newsletter .inputs {
    display: block;
    width: 50%;
    margin: 0 auto;
}

#subscribe-form .input-box {
    float: left;
    margin: 0 1%;
}

#subscribe-form .input-text,
#subscribe-form .button {
    font-family: 'Barlow', sans-serif;
}

div#subscribe-form div.input-box input.input-text {
    font-size: 15px;
}

.button.btn-inline.newsletter-button {
    width: 20%;
    margin: 0;
    border-radius: 30px;
}

.button.button.btn-inline.newsletter-button span span:hover {
    background-color: #fff;
    color: #041e42;
    border-radius: 30px;
}

.newsletter button.button span {
    width: 100%;
    text-transform: uppercase;
    border-radius: 30px;
}

.block-account .block-title {
    background: none;
}

.product-options.border-out {
    border: 1px solid red;
    border-radius: 5px;
    padding: 10px;
}

.product-options.border-out:after {
    content: 'Please select the options';
    color: red;
    font-size: 1.2em;
}

.add-cart-fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    background-color: #111;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
}

.add-cart-fixed span {
    height: 45px;
    line-height: 45px;
    font-size: 1.1666em;
    text-transform: uppercase;
}

.form-list .field {
    position: relative;
}

/* Hot Deals Module */
div[id*="hot_deals"] {
    padding-bottom: 50px;
}

div[id*="hot_deals"] .owl-item.active {
    padding-bottom: 10px !important;
}

.short-hot-deals div[id*="hot_deals"] .owl-item.active {
    max-height: 370px !important;
}

/*--CONTACT PAGE --*/
.form-list input.input-text {
    width: 100%;
}

.xtra-margin {
    margin: 2%;
}

.officeMenuTitle {
    top: 185px;
}

.contact-nav {
    display: flex;
    flex-flow: row wrap;
    background-color: #f1f1f1;
    margin: 10px 0;
}

.contact-nav li {
    line-height: 50px;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.contact-nav a {
    padding: 16px;
    text-transform: uppercase;
    color: #041e42;
    font-weight: 700;
}

.contact-nav .dropdown-nav li a {
    color: #041e42;
    font-weight: 400;
}

.contact-nav .dropdown-nav {
    display: none;
    position: absolute;
    box-shadow: 0px 4px 9px #e5e5e5;
    background-color: #041e42;
    width: 100%;
    z-index: 1;
    left: 0;
}

.contact-nav li:hover .dropdown-nav {
    display: block;
}

.contact-nav li.active,
.contact-nav li:hover {
    background-color: #041e42;
}

.contact-nav li.active a,
.contact-nav li:hover a,
.contact-nav .dropdown-nav li:hover a {
    color: #fff;
}

.contact-nav .dropdown-nav li:hover a {
    color: #717171;
}

.nav-office-menu>.col-main {
    background-color: #717171;
    margin: 0;
    width: 100%;
}

.nav-office-menu {
    background-color: #717171;
}

#j-locations li.level0>a {
    line-height: 49px;
}

#j-locations li.level0.first>a {
    padding-left: 0;
}

#j-locations .nav-regular .nav-item.level0:hover>a,
#j-locations .nav-mobile .nav-item.level0>a:hover {
    color: #041e42;
}

.j-contact-form li:nth-child(1) {
    width: 48%;
    margin-right: 4%;
    float: left;
}

.j-contact-form li:nth-child(2) {
    width: 48%;
    float: left;
}

.j-contact-form li:nth-child(3) {
    width: 48%;
    float: right;
}

#how-can-we-help-you {
    width: 100%;
}

.j-contact-form button {
    float: right !important;
}

@media screen and (max-width:767px) {
    .contact-nav li {
        width: 100%;
    }

}

/* ================================================ */
/* Footer */
/* ================================================ */
/*Footer*/
#footer a {
    text-transform: uppercase;
}

#footer {
    font-size: 14px;
    margin-top: 30px;
}

ul.bullet li {
    background: none;
    padding-left: 0px;
    text-transform: capitalize;
    font-family: 'Barlow', sans-serif;
    letter-spacing: 0.5px;
}

#footer a {
    color: #fff;
}

#footer ul li a {
    text-decoration: none;
    line-height: 1.75em;
    color: #fff;
    font-size: 1em;
    font-weight: 400;
}

#footer ul.footer-links-inline li>a {
    font-size: 16px;
    line-height: normal;
}

#footer ul li a:hover {
    border-bottom: 1px solid #fff;
}

h6.block-title.heading {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.footer-top-container img {
    opacity: 1;
    -webkit-transition: opacity 0.15s ease-out;
    -moz-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
}

.footer-bottom-container {
    background-color: transparent;
}

.footer-bottom-container .section.clearer {
    padding: 0;
}

.footer-copyright {
    color: #b3b3b3;
    text-transform: capitalize;
    font-family: 'Barlow', sans-serif;
    letter-spacing: 0.5px;
    font-size: 1em;
    font-weight: 400;
}

/*Footer Copyright*/
.footer-copyright {
    display: none;
}

.copyright1 {
    background: transparent;
    text-transform: capitalize;
    font-family: 'Barlow', sans-serif;
    letter-spacing: 0.5px;
    font-size: 1em;
    font-weight: 400;
    color: #b3b3b3;
}

.footer-container {
    background-color: #041e42;
    color: #fff;
}

.footer-top .section {
    padding: 20px 0;
}

.footer-primary>.grid-full>.section,
.footer-secondary>.grid-full>.section {
    padding: 20px 0;
}

.footer-links-inline li {
    float: left;
    display: inline-block;
    margin-right: 40px;
}

.footer-row-1 .section.clearer {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.footer .social-links {
    text-align: center;
}

.footer .social-links a {
    font-size: 25px;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    border: 2px solid #fff;
    display: inline-block;
    line-height: 44px;
    text-align: center;
    margin-left: 12px;
}

.footer .social-links a i {
    display: block;
    line-height: 44px;
    text-align: center;
}

.footer .social-links a:first-child {
    padding-left: 0;
}

@media screen and (min-width:768px) {

    .request-form.pl-form .form-list ul li,
    .request-form.pl-form .form-list div.hide {
        clear: left;
    }

    .request-form.pl-form .form-list li:nth-of-type(n+3) {
        float: left;
        margin-right: 5px;
        clear: none;
    }
}

@media screen and (max-width:767px) {
    .footer .social-links {
        text-align: left;
        padding-top: 20px;
    }

    .footer {
        text-align: center;
    }
}

@media print {

    #header,
    #footer,
    .related-items,
    .itemslider-wrapper,
    .xm-grid-product .product-shop>div[class*="grid12-"]>div:not(.product-name),
    #product-disclaimer,
    #product-tabs li:first-of-type~li {
        display: none;
    }

    div.main-container.col1-layout {
        padding-top: 0 !important;
        padding-bottom: 0;
    }

    .gen-tabs .tabs a {
        line-height: 2em;
        font-size: 12px;
    }

    .product-view td:first-child,
    .xm-grid-product td:first-child,
    .product-view td,
    .xm-grid-product td {
        font-size: 10px;
    }

    .product-view td,
    .xm-grid-product td {
        padding: 2px;
    }
}